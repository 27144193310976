const API_BASE_URL = process.env.REACT_APP_BASE_URL + "rest/V1/"; //'https://b2cdevserver.redingtonmea.com/rest/V1/';
const API_BASE_URL_DEFAULT =
  process.env.REACT_APP_BASE_URL + "rest/default/V1/";
const SIZE_LIST_URL = "products/attributes/142/options";
const COLOR_LIST_URL = "products/attributes/93/options";
//const SIZE_LIST_URL = 'products/attributes/145/options';
const PRODUCT_SEARCH =
  "products?searchCriteria[filter_groups][0][filters][1][field]=status&searchCriteria[filter_groups][0][filters][1][value]=1&searchCriteria[filter_groups][0][filters][0][field]=category_id&searchCriteria[filter_groups][0][filters][0][value]=";
const ALL_PRODUCT_SEARCH =
  "products?searchCriteria[filter_groups][0][filters][1][field]=status&searchCriteria[filter_groups][0][filters][1][value]=1";
const CHECK_ORDER_RETURN = "checkOrderReturn";
const PRODUCT_BY_ID = "products/";
const CONFIRM_RETURN = "requestOrderReturn";
const CATEGORY_LIST = "categories";
const GUEST_CART = "guest-carts/";
const PRODUCT_ENQUIRY = "redington-outofstockproductenquiry/productenquiry";
const COUNTRY_LIST = "directory/countries";
const CUSTOMERS = "customers";
const LOGIN_USER = "integration/customer/token";
const CARTS_MINE = "carts/mine";
const ITEMS = "/items";
const CUSTOMER = "customers/me";
const SHIPPINGMETHOD = "/shipping-methods";
const PREVIOUSORDERS =
  "orders?searchCriteria[filter_groups][0][filters][0][field]=customer_email&searchCriteria[filter_groups][0][filters][0][value]=";
const PRODUCTSQUERY = "products?";
const BANNERSLIDERS = "bannersliders";
const RECOMMENDEDPRODUCTS =
  "products?searchCriteria[filter_groups][0][filters][0][field]=on_home&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=gteq&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1";
const SEARCH_PARAMS =
  "?searchCriteria[sortOrders][0][field]=identifier&searchCriteria[sortOrders][0][direction]=asc&searchCriteria[filterGroups][0][filters][0][field]=identifier&searchCriteria[filterGroups][0][filters][0][value]=";
const COOKIEPOLICY_PARAMS =
  "?searchCriteria[filter_groups][0][filters][0][field]=status&searchCriteria[filter_groups][0][filters][0][value]=yes";
const VIDEOURL_PARAMS =
  "?searchCriteria[filter_groups][0][filters][0][field]=status&searchCriteria[filter_groups][0][filters][0][value]=yes";

const SENDOTP = "otp/send";
const VERIFYOTP = "otp/verify";

const UPDATEPASSWORD = "password/update";
// /rest/<store_code>/V1/products?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=&searchCriteria[filter_groups][0][filters][0][condition_type]=like

const RETURN_MANAGEMENT = "returnView?customer_email=";

const GENERATE_INVOICE = "b2cgetinvoice";

const GETDOMAINDETAILS = "access-mapping/getDomainDetails";
const getCategory = "category-restriction/getCategories/";

const maxOrderQty = "max-order-qty/getMaxOrderQty/";
const userInfo = "customer-info/getCustomerInfo";
const CHECKSUBSCRIPTIONCODE = "check-subscriptioncode/";
const COOKIE_POLICY = "redington-cookiepolicy/cookiepolicy/search";
const VIDEO_URL = "redington-homevideo/homevideo/search";

const apiList = {
  colorList: `${API_BASE_URL_DEFAULT}${COLOR_LIST_URL}`,
  sizeList: `${API_BASE_URL_DEFAULT}${SIZE_LIST_URL}`,
  productSearch: `${API_BASE_URL}${PRODUCT_SEARCH}`,
  allProductSearch: `${API_BASE_URL}${ALL_PRODUCT_SEARCH}`,
  productById: `${API_BASE_URL}${PRODUCT_BY_ID}`,
  checkOrderReturn: `${API_BASE_URL}${CHECK_ORDER_RETURN}`,
  confirmReturn: `${API_BASE_URL}${CONFIRM_RETURN}`,
  categoryList: `${API_BASE_URL_DEFAULT}${CATEGORY_LIST}`,
  session: `${API_BASE_URL}${GUEST_CART}`,
  productEnquiry: `${API_BASE_URL}${PRODUCT_ENQUIRY}`,
  cartItems: "items",
  estimateShipping: "/estimate-shipping-methods",
  shippingInformationBase: `${API_BASE_URL_DEFAULT}${GUEST_CART}`,
  shippingInformation: "/shipping-information",
  order: "/order",
  getCountry: `${API_BASE_URL}${COUNTRY_LIST}`,
  userRegisteration: `${API_BASE_URL}${CUSTOMERS}`,
  userLogin: `${API_BASE_URL}${LOGIN_USER}`,
  createUserCartSession: `${API_BASE_URL}${CARTS_MINE}`,
  userCartItems: `${API_BASE_URL}${CARTS_MINE}${ITEMS}`,
  getCustomerDetails: `${API_BASE_URL_DEFAULT}${CUSTOMER}`,
  userShippingMethod: `${API_BASE_URL}${CARTS_MINE}${SHIPPINGMETHOD}`,
  updateUserDetails: `${API_BASE_URL}${CUSTOMERS}/me`,
  userShippingBase: `${API_BASE_URL_DEFAULT}${CARTS_MINE}`,
  createrOrder: `${API_BASE_URL_DEFAULT}${CARTS_MINE}/b2cpayment-information`,
  getOrders: `${API_BASE_URL}${PREVIOUSORDERS}`,
  searchProducts: `${API_BASE_URL}${PRODUCTSQUERY}`,
  bannerSliders: `${API_BASE_URL}${BANNERSLIDERS}`,
  recommendedProducts: `${API_BASE_URL}${RECOMMENDEDPRODUCTS}`,
  getEmailDomainForCountryCode: `${API_BASE_URL}${GETDOMAINDETAILS}`,

  /*OTP Related API */
  sendOTP: `${API_BASE_URL}${SENDOTP}`,
  verifyOTP: `${API_BASE_URL}${VERIFYOTP}`,

  /*Password Related */
  updatePassword: `${API_BASE_URL}${UPDATEPASSWORD}`,

  //Return management
  returnManagement: `${API_BASE_URL}${RETURN_MANAGEMENT}`,
  //Generate invoice
  generateInvoice: `${API_BASE_URL}${GENERATE_INVOICE}`,
  //To generate Order History response in Ascending order.
  orderHistorySorted:
    "&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC",
  paymentSessionUrl:
    process.env.REACT_APP_MERCHANT_URL +
    process.env.REACT_APP_PAYMENT_SESSION_URL,
  // paymentValidationUrl: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PAYMENT_VALIDATION_URL
  // paymentSessionUrl:
  //   "https://test-epaygib.mtf.gateway.mastercard.com/api/rest/version/65/merchant/",
  paymentValidationUrl: `${process.env.REACT_APP_BASE_URL}rest/V1/payment-status`,
  termsAndConditionsUrl:
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
  aboutUsAndPrivacyPolicyBackground:
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_ABOUT_US_AND_PRIVACY_POLICY_BACKGROUND,
  privacyPolicyContentUrl:
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_PRIVACY_POLICY_CONTENT_URL,
  aboutUsContentUrl:
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ABOUT_US_CONTENT_URL,
  searchUsContentUrl: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEARCH_US_CONTENT_URL}${SEARCH_PARAMS}`,
  cookiePolicyUrl: `${API_BASE_URL}${COOKIE_POLICY}${COOKIEPOLICY_PARAMS}`,
  videoUrl: `${API_BASE_URL}${VIDEO_URL}${VIDEOURL_PARAMS}`,
  paymentErrorUrl:
    process.env.REACT_APP_URL_FOR_PAYMENT +
    process.env.REACT_APP_PAYMENT_ERROR_URL,
  paymentCancelUrl:
    process.env.REACT_APP_URL_FOR_PAYMENT +
    process.env.REACT_APP_PAYMENT_CANCEL_URL,
  paymentSuccessUrl:
    process.env.REACT_APP_URL_FOR_PAYMENT +
    process.env.REACT_APP_PAYMENT_SUCCESS_URL,
  paymentMerchantSession:
    process.env.REACT_APP_MERCHANT_URL +
    process.env.REACT_APP_MERCHANT_SESSION_URL,
  returnPolicyUrl:
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_RETURN_POLICY_URL,
  logoForPayment:
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGO_FOR_PAYMENT,
  urlForPayment: process.env.REACT_APP_URL_FOR_PAYMENT,
  shippingTrackingUrl: process.env.REACT_APP_SHIPPING_TRACKING,
  getCategory: API_BASE_URL + getCategory,
  maxOrderQty: API_BASE_URL + maxOrderQty,
  userInfo: API_BASE_URL + userInfo,
  checkStockInfo: API_BASE_URL + "checkout/check-stock-data",
  ccAvenuePayment: API_BASE_URL + "carts/mine/cc-avenuepayment-information",
  userCheckoutTermsAndCondition:
    API_BASE_URL + "redington-termsconditions/termsconditions/getlist",
  paymentStatusCCAvenue: API_BASE_URL + "payment-status",
  setEnquiryData: API_BASE_URL + "set-enquiry-data/setEnquiryData",
  cookiePolicyContentUrl:
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_COOKIE_POLICY_CONTENT_URL,
  termsAndConditionPolicyContentUrl:
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_TERMSANDCONDITION_POLICY_CONTENT_URL,
  returnPolicyContentUrl:
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_RETURN_POLICY_CONTENT_URL,
};

export const queryParams = {
  accessId:
    "&searchCriteria[filter_groups][0][filters][0][field]=access_id&searchCriteria[filter_groups][0][filters][0][value]=",
};

export default apiList;