import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setCookie } from "constants/utils";
import SystemConstant from "constants/SystemConstant";
import API_LIST from "actions/apiList";
import { setAlert } from "actions/appActions";

//css
import '../../assets/css/cookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(!SystemConstant.cookies.get('userConsent'));
  const [cookiePolicyFile, setCookiePolicyFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!showBanner) return;
    const headers = SystemConstant.adminRequestHeaders;
    setLoading(true)
    axios
      .get(`${API_LIST.cookiePolicyUrl}`, { headers })
      .then((response) => {
        let data = response.data;
        if(data?.total_count > 0 && Array.isArray(data.items)) {
          setCookiePolicyFile(data.items[0].uploadpdf)
        }
      })
      .catch(() =>
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        )
      )
      .finally(() => setLoading(false));;
  },[])

  const handleAccept = () => {
    setCookie("userConsent", 'accepted');
    setShowBanner(false);
  };

  const handleDecline = () => {
    setCookie("userConsent", 'declined');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <div className="cookie-message">
        <p>
          We respect your privacy and aim for the best website experience in compliance with the UAE PDPL. Allowing cookies enables a tailored experience, while disabling them may reduce personalization. Feel free to{' '}
          update your preferences anytime.
          {loading ? (
            <>Loading policy...</>
          ) : cookiePolicyFile ? (
            <>
              For more information, please read our{' '}
              <a href={cookiePolicyFile} target="_blank" rel="noopener noreferrer">Cookie Policy</a>.
            </>
          ) : (
            <>The cookie policy is currently unavailable.</>
          )}
        </p>
      </div>
      <div className="cookie-actions">
        <button onClick={handleAccept}>Accept</button>
        <button onClick={handleDecline}>Decline</button>
      </div>
    </div>
  );
};

export default CookieBanner;
