import {
  GET_CATEGORY_LIST,
  SET_ALERT,
  SET_TOAST,
  SET_SEARCH_PRODUCTS,
  SET_SEARCH_LOADER,
  SET_SEARCH_PRODUCT_ATTR,
  SET_VIDEO_URL
} from "actions/types";
import { getColorSizeAndProducts } from "constants/utils";

let initialState = {
  categoryList: [],
  alerts: [],
  toasts: [],
  searchText: "",
  searchItems: [],
  videoUrlData: [],
  searchLoader: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST: {
      return {
        ...state,
        categoryList: action.payload
          .filter(
            (category) => category.is_active && category.children_data.length
          )
          .map((category) => {
            category.routeName = category.name
              .replace(/\s+/g, "-")
              .toLowerCase()
              .replace("'", "");
            return category;
          }),
      };
    }
    case SET_ALERT: {
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    }
    case SET_TOAST: {
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    }
    case SET_SEARCH_PRODUCTS: {
      const { name, items, colorList, sizeList } = action.payload;
      let searchItemsTemp = items.filter((product) => product.visibility === 4 && product?.extension_attributes?.stock_item?.qty > 0 && product?.price > 0);
      let newProducts = [];
      if (searchItemsTemp.length) {
        newProducts = searchItemsTemp.map((product) => {
          return getColorSizeAndProducts(product, colorList, sizeList, items);
        });
      }
      return {
        ...state,
        searchText: name,
        searchItems: newProducts,
      };
    }
    case SET_SEARCH_PRODUCT_ATTR: {
      const { attrName, atttrValue, sku } = action.payload;
      let index = state.searchItems.findIndex((pr) => pr.sku === sku);
      let tempSearchItems = [...state.searchItems];
      tempSearchItems[index][attrName] = atttrValue;
      return {
        ...state,
        searchItems: tempSearchItems,
      };
    }
    case SET_SEARCH_LOADER: {
      return {
        ...state,
        searchLoader: action.payload,
      };
    }
    case SET_VIDEO_URL: {
      return {
        ...state,
        videoUrlData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
