import { ADD_SHIPPING_METHODS, SET_SHIPPING_INFO, RESET_ORDER, TERMS_AND_CONDITIONS } from "./types";
import SystemConstant from "constants/SystemConstant";
import axios from "axios";
import API_LIST from "./apiList";
import { clearUserData, createUserCartSession } from "./signinActions";
import { setAlert } from "./appActions";
import { checkAllCookie } from "constants/utils";
import { getSession } from "./cartActions";

export function estimateShipping(shippingPayLoad) {
  return (dispatch) => {
    if(!checkAllCookie()) {
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.getUserRequestHeaders();
    axios
      .post(
        `${API_LIST.userShippingBase}${API_LIST.estimateShipping}`,
        shippingPayLoad,
        { headers }
      )
      .then((response) => {
        if (response.data && response.data.length) {
          dispatch(addShippingMethods(response.data));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotEstimateShippingMethod}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch(() =>
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotEstimateShippingMethod}`,
            SystemConstant.warning
          )
        )
      );
    }
  };
}

export function getShippingInformation(shippingPayLoad, cb) {
  return (dispatch) => {
    if(!checkAllCookie()) {
      cb();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.getUserRequestHeaders();
    axios
      .post(
        `${API_LIST.userShippingBase}${API_LIST.shippingInformation}`,
        shippingPayLoad,
        { headers }
      )
      .then((response) => {
        if (response.data) {
          dispatch(setShippingInfo(response.data));
          cb();
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetShippingEstimateText}`,
              SystemConstant.warning
            )
          );
          cb();
        }
      })
      .catch((err) => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetShippingEstimateText}`,
            SystemConstant.warning
          )
        );
        cb();
      });
    }
  };
}

export function createOrder(orderPayload, cb, cbFailure) {
  return (dispatch) => {
    if(!checkAllCookie()) {
      cbFailure();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.getUserRequestHeaders();
    axios
      .post(`${API_LIST.createrOrder}`, orderPayload, { headers })
      .then((response) => {
        if (response.data) {
          cb(response.data);
          dispatch(resetOrder());
          dispatch(createUserCartSession(() => {}));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.orderCreateFailedText}`,
              SystemConstant.warning
            )
          );
          cbFailure();
        }
      })
      .catch((err) => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.orderCreateFailedText}`,
            SystemConstant.warning
          )
        );
        cbFailure();
      });
    }
  };
}

export function createOrderCCAvenue(orderPayload, cb, cbFailure) {
  return (dispatch) => {
    if(!checkAllCookie()) {
      cbFailure();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.getUserRequestHeaders();
    axios
      .post(`${API_LIST.ccAvenuePayment}`, orderPayload, { headers })
      .then((response) => {
        if (response.data) {
          cb(response.data);
          dispatch(resetOrder());
          dispatch(createUserCartSession(() => {}));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.orderCreateFailedText}`,
              SystemConstant.warning
            )
          );
          cbFailure();
        }
      })
      .catch((err) => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.orderCreateFailedText}`,
            SystemConstant.warning
          )
        );
        cbFailure();
      });
    }
  };
}

export function getUserCheckoutTermsAndConditions() {
  return (dispatch) => {
    const access_code = SystemConstant.cookies.get("access_code");
    const headers = SystemConstant.getUserRequestHeaders();
    const postBody = {
      accessId: access_code
    };
    axios
      .post(`${API_LIST.userCheckoutTermsAndCondition}`, postBody, { headers })
      .then((response) => {
        if (response.data[0].status === "success") {
          dispatch(setTermsAndConditions(response.data[0].data));
        } else if (response.data[0].status === "failed") {
          dispatch(setTermsAndConditions(response.data[0].data));
        } else {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText}`,
              SystemConstant.warning
            )
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        );
      });
  };
}

export function addShippingMethods(methods) {
  return {
    type: ADD_SHIPPING_METHODS,
    payload: methods,
  };
}

export function setTermsAndConditions(data) {
  return {
    type: TERMS_AND_CONDITIONS,
    payload: data,
  };
}

export function setShippingInfo(info) {
  return {
    type: SET_SHIPPING_INFO,
    payload: info,
  };
}

export function resetOrder() {
  return {
    type: RESET_ORDER,
  };
}
